<template>
  <div class="journal_detail_wrap">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <div class="title">
      <h1>{{detailData.title}}</h1>
      <p><b>{{detailData.createTime}}</b><strong>{{detailData.author}}</strong></p>
    </div>
    <div class="journal_detail">
      <div v-html="detailData.article" class="journal_detail_con"></div>
    </div>

  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { JOURNAL, JOURNALDETAIL } from "@/request/common";
export default {
  name: 'journalDetail',
  metaInfo: {
    title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
    meta: [{                 // set meta
      name: 'keyWords',
      content: '商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
    }, {                 // set meta
      name: 'description',
      content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
    }],
  },
  data () {
    return {
      detailData: ''
    }
  },
  mounted () {
    setTimeout(() => {
      this.init();
    }, 500);
  },
  methods: {
    init () {
      JOURNALDETAIL({
        'articleId': this.$route.query.id,
        'userId': this.me ? this.me.userId : ''
      }).then((data) => {
        if (data.code == 0 && data.data != null) {
          this.detailData = data.data;
        } else {
          this.$message(data.msgEn);
        }
      }).catch((error) => {
        // this.$message("系统未知错误,请反馈给管理员");
        console.log(error);
      })
    }
  },
  computed: {
    ...mapState(["me"])
  },
}
</script>
<style lang="less" scoped>
.journal_detail_wrap {
  background: white;
  height: 100%;
  width: 100%;

  .title {
    margin: 0 auto;

    h1 {
      color: #1d1d1d;
      font-size: 38px;
    }

    p {
      font-size: 16px;
      color: #a1a1a1;
      margin: 25px 0 50px;

      b,
      strong {
        font-weight: inherit;
      }

      strong {
        margin-left: 40px;
      }
    }
  }

  .journal_detail {
    width: 1200px;
    margin: 0 auto;
    /deep/ul {
      /deep/li {
        list-style: disc!important;
      }
    }
  }
}
</style>
<style lang="less">
.journal_detail_wrap {
  .journal_detail_con {
    strong {
      font-weight: 900;
    }
  }
  .journal_detail {
    width: 1200px;
    margin: 0 auto;
    ul {
      li {
        list-style: disc!important;
      }
    }
  }
}
</style>
